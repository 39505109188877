.body-content {
  margin-top: -63px;
  background-color: #111325;
}

.brand-color {
  color: #0062ff;
}

.brand-color-black {
}

.brand-color-white {
  color: #e5ecf4;
}

.brand-color-shadow-black {
  color: #111325;
}

.brand-bgcolor-shadow-black {
  background-color: #111325;
}

.brand-color-grey {
  color: #c2c2c3;
}

.content-padding {
  padding: 0 9%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.font-bold {
  font-weight: 600 !important;
}

@media (max-width: 576px) {
  .content-padding {
    padding: 0 4%;
  }
  .airdrop-header { 
    height: 4rem !important;
  }
}

.airdrop-header {
  /* background-color: #1a1d3a; */
  background-image: linear-gradient(35deg, #0062ff, #192020, #192020, #0062ff);
  /* background-image: linear-gradient(35deg, #0062ff, #e5ecf4, white, #e5ecf4, #0062ff); */
  color: #e5ecf4;
  font-weight: 600;
  letter-spacing: 0.8px;
  /* border: solid 1px #0062ff; */
  height: 3rem;
  display: flex;
  align-items: center;
}

.airdrop-header-content {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
}

.airdrop-header-content a {
  color: #e5ecf4;
}

.airdrop-header-content a:hover {
  color: #e5ecf4;
}

.airdrop-header-close {
  padding-right: 1.5rem;
  cursor: pointer;
}
