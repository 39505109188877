.brand-logo {
    height: 2.6rem;
    margin-left: 5rem;
}

.btn-brand-header {
    color: #e5ecf4 !important;
    background-color: #0062ff;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    border-radius: 2px;
    padding: 6px 23px;
}

.navbar {
    background-color: transparent;
    padding-top: 15px;
}

.navbar-list {
    margin-right: 5rem;
}

.navbar .nav-item .dropdown-menu {
    display: none;
}
.navbar .nav-item:hover .nav-link {
}
.navbar .nav-item:hover .dropdown-menu {
    display: block;
}
.navbar .nav-item .dropdown-menu {
    margin-top: 0;
}

.nav-link.dropdown-toggle  {
    font-size: 1rem;
    font-weight: normal;
}

.navbar-nav .nav-link:hover {
    font-size: 1rem;
    font-weight: normal;
}

.navbar-nav .nav-link:active {
    font-size: 1rem;
    font-weight: normal;
}

.navbar-nav .nav-link:focus {
    font-size: 1rem;
    font-weight: normal;
}

.dropdown-menu {
    background-color: #1a1d3a;
    color: #e5ecf4;
}

.dropdown-item {
    color: #e5ecf4;
}

.dropdown-item:active, .dropdown-item:hover {
    background-color: #0062ff;
    color: #111325;
}

.apply-btn:hover {
    font-size: 0.9rem !important;
    font-weight: 600 !important;
}

@media (max-width: 576px) {
    .brand-logo {
        margin-left: 0.5rem;
    }

    .navbar {
        background-color: #111325;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-list {
        margin-top: 1rem;
        margin-right: 0rem;
    }

    .connect-wallet-btn {
        margin-top: 0.5rem;
    }

    .connect-wallet-btn,
    .apply-btn {
        margin-bottom: 1rem;
    }
    
    .dropdown-menu { 
        margin-bottom: 1rem;
    }
    
    .dropdown-item {
        text-align: center;
    }
}
