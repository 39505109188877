@font-face {
  font-family: 'sfpro';
  src: local('sfpro'), url('./../fonts/SFPro.ttf') format('truetype');
}

html {
  min-height: 100% !important;
}

body {
  margin: 0;
  font-family: 'sfpro' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.5px;
  display: flex;
  min-height: 100vh !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 576px) { 
  body {
    font-size: 0.9rem !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}
