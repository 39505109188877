.confirm-button {
    color: #e5ecf4 !important;
    background-color: #0062ff;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    border: none;
    border-radius: 2px;
    padding: 8px 23px;
}

.confirm-button:hover, .confirm-button:active, .confirm-button:focus {
    background-color: #0062ff !important;
}

.vesting-vault-explorer-container {
    // .range-slider:read-only {
    .range-slider:hover {
        cursor: none;
    }
    // }
    .badge-rotate {
        transform: rotate(-90deg);
    }
    .btn-brand-border {
        color: #e5ecf4;
        border: 1px #e5ecf4 solid;
        // padding: 1% 3%;
    }
    .progress-bar {
        height: 20px;
        background-color: transparent;
        margin-bottom: 0.2rem;
    }
    .vault-content {
        padding: 0 9%;
    }
    .project-details-container {
        background-color: #1a1d3a;
        padding: 1rem;
        border-radius: 10px;

        .project-details {
            .project-icon {
                width: 100px;
            }
            background-color: #111325;
            padding: 1rem;
            border-radius: 10px;
            margin-right: 1rem;
        }
        .lock-overview {
            background-color: #111325;
            padding: 1rem;
            border-radius: 10px;
        }
    }

    .icon-details {
        width: 1.7rem;
    }

    .error-message-container {
        background-color: #1a1d3a;
        padding: 1rem;
        h5 {
            padding: 1rem;
            background-color: red;
            margin-bottom: 0;
            background-color: #111325;
            border-radius: 5px;
        }
        border-radius: 5px;
    }

    .claim-dashboard {
        .transfer-ownership-button {
            color: #e5ecf4 !important;
            background-color: #0062ff;
            padding: 5px 20px;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: 0.4px;
            border: none;
            border-radius: 2px;
            padding: 6px 23px;
        }

        .claim-dashboard-content {
            background-color: #1a1d3a;
            padding: 1rem;
            border-radius: 10px;

            .claim-form-container {
                background-color: #111325;
                border-radius: 10px;
                margin-right: 1rem;
                max-width: 42%;
                .claim-dashboard-withdraw {
                    background-color: #111325;
                    border-radius: 10px;
                    height: 100%;
    
                    .form-container {
                        padding: 0rem 1.5rem;
                    }
                    // margin-right: 1rem;
    
                    .input-field {
                        color: #e5ecf4;
                        background-color: transparent;
                        border: 3px solid #272727;
                        border-radius: 5px;
                        font-size: 1rem;
                    }
    
                    .claim-button {
                        color: #e5ecf4 !important;
                        background-color: #0062ff;
                        padding: 5px 20px;
                        font-size: 1rem;
                        font-weight: 600;
                        letter-spacing: 0.4px;
                        border: none;
                    }
                }
            }

            .account-details-container {
                background-color: #111325;
                border-radius: 10px;
                max-width: 58%;
                .first-container {
                    margin-right: 0.5rem;
                }
                .second-container {
                    margin-left: 0.5rem;
                }
                .flip-container {
                    display: block;
                    perspective: 1200px;
                    position: relative;
                    max-width: 50%;
                }

                .flipcard-body {
                    display: flex;
                    transition: transform 0.4s;
                    transform-style: preserve-3d;
                    .flip-icon {
                        width: 2rem;
                        border-radius: 50%;
                        position: absolute;
                        right: 1rem;
                        cursor: pointer;
                    }
                    .front-container {
                        background-color: #111325;
                        backface-visibility: visible;
                        -webkit-backface-visibility: visible;
                        transition: opacity 0s 0.2s;
                        flex: 1;
                        margin-right: -100%;
                        max-width: 100%;
                    }
                    .back-container {
                        background-color: #1a1d3a;
                        backface-visibility: hidden;
                        -webkit-backface-visibility: hidden;
                        transform: rotateY(180deg);
                        flex: 1;
                        .flip-icon {
                            transform: scaleX(-1);
                        }
                    }
                }

                .flipped {
                    .flipcard-body {
                        transform: rotateY(-180deg);
                    }
                    .front-container {
                        opacity: 0;
                        transition: opacity 0s 0.25s;
                        backface-visibility: hidden;
                        -webkit-backface-visibility: hidden;
                        flex: 1;
                        margin-right: -100%;
                        max-width: 100%;
                    }
                    .back-container {
                        backface-visibility: visible;
                        -webkit-backface-visibility: visible;
                    }
                }

                .claim-dashboard-item {
                    background-color: #111325;
                    padding: 1rem 0.4rem 1rem 0.4rem;
                    border-radius: 10px;
    
                    .total-vested {
                        border: 2px solid #272727;
                        border-radius: 10px;
                        padding: 1rem 0.5rem;
    
                        .vesting-icon {
                            border: 1px solid #272727;
                            padding: 0.5rem;
                            border-radius: 50%;
                        }
    
                        .vested-amount {
                            color: #0062ff !important;
                            font-size: 1rem;
                            font-weight: 600;
                            letter-spacing: 0.4px;
                            border: 1px solid #0062ff;
                            border-radius: 1px;
                            padding: 0.3rem 2rem;
                        }
                    }
    
                    .total-withdrawn {
                        border: 2px solid #272727;
                        border-radius: 10px;
                        padding: 1rem 0.5rem;
    
                        .withdraw-icon {
                            border: 1px solid #272727;
                            padding: 0.5rem;
                            border-radius: 50%;
                        }
    
                        .withdrawn-amount {
                            color: #0062ff !important;
                            font-size: 1rem;
                            font-weight: 600;
                            letter-spacing: 0.4px;
                            border: 1px solid #0062ff;
                            border-radius: 5px;
                            padding: 0.3rem 2rem;
                        }
                    }
                }
            }

            

            .detail-container {
                background-color: #111325;
                padding: 1rem 2rem;
                border-radius: 10px;
            }

        }
    }

    @media (max-width: 576px) {
        h5 {
            font-size: 0.9rem !important;
        }
        h2 {
            font-size: 1.2rem !important;
        }
        h6 {
            font-size: 0.8rem !important;
        }
        .vault-content {
            padding: 0 2%;
        }
        .project-details-container {
            font-size: 0.85rem;
            flex-direction: column;
            margin-right: 0;
            .project-details {
                .project-icon {
                    width: 50px;
                }
                margin-bottom: 1rem;
            }
        }
        .title-filter-container {
            flex-direction: column;
        }

        .claim-dashboard {
            .claim-dashboard-content {
                padding: 1rem;
                display: flex;
                flex-direction: column;
                .claim-form-container {
                    max-width: 100%;
                    padding: 2.5rem 0.5rem;
                }
    
                .account-details-container {
                    max-width: 100%;
                    .claim-dashboard-item {
                        display: flex;
                        flex-direction: column;
                        .first-container {
                            margin-right: 0rem;
                            margin-bottom: 1rem;
                        }
                        .second-container {
                            margin-left: 0rem;
                        }
                        .flip-container {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
        
    }
}
