.btn-logout {
    color: #e5ecf4;
    border: 1px white solid;
    padding: 1% 6%;
}

.btn-view-on {
    color: #111325;
    background-color: #0062ff;
    padding: 1% 6%;
}

.btn-logout:hover {
    color: #0062ff
}
/* 
.btn-brand {
    color: #e5ecf4;
    background-color: #0062ff;
    padding: 1% 5%;
}

.btn-brand-border {
    color: #e5ecf4;
    border: 1px white solid;
    padding: 1% 3%;
}

.btn-brand-border:hover {
    color: #0062ff
} */