.modal-header {
    border-bottom: none;
    background-color: #1a1d3a;
}

.modal-header>.close {
    color: white
} 

.wallet-item {
    background-color: #1a1d3a;
    border: 1px solid #5C6078;
    margin: 1.3rem 0.3rem;
    cursor: pointer;
    border-radius: 10px;
}

.wallet-item:hover {
    background-color: inherit
}