.footer {
    background-color: #111325;
    background-size: cover;
    height: 7rem;
    /* position: relative; */
    width: 100%;
    bottom: 0;
    margin-top: -7rem;
}

.fooetr-logo {
    width: 10rem;
}

.logo-powered-by:hover {
    cursor: pointer;
}

.social-icon {
    height: 1rem;
}

.info {
    text-decoration: none;
    color: #e5ecf4;
    padding: 0.2rem 2.5rem;
}

.info:hover, .info:active {
    text-decoration: none;
    color: #e5ecf4;
}

.info-litepaper {
    border-right: 1px solid white;
}

.divider {
    border-top: 1px solid white;
    opacity: 0.18;
}

.social-icons > a, .social-icons > a:hover {
    text-decoration: none;
}

.btn-apply-for-ido {
    background-color: #0062ff;
    border-radius: 25px;
    text-decoration: none;
}

.btn-apply-for-ido:hover {
    text-decoration: none;
}

.btn-apply-for-ido-left {
    height: 45px;
    width: 45px;
    border: 2px #0062ff solid;
    border-radius: 25px ;
    background-color: #151515 !important;
    /* padding: 0rem 1rem; */
    /* padding: 6px 10px; */
}

.btn-apply-for-ido-right {
    height: 45px;
    color: #111325;
    background-color: #0062ff;
    padding: 0px 20px 0px 5px;
    border-radius: 0px 25px 25px 0px;
    line-height: 28px;
    font-size: 1.15rem;
}

.footer-link {
    color: white
}

.footer-link:hover {
    color: #0062ff;
}